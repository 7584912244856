import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import HeaderBox from "src/components/HeaderBox"
import Spacer from "src/components/spacer"
import Wrap from "src/components/wrap"
import PatternStyle from "src/components/pattern.module.scss"
import Style from "./foodhall.module.scss"

import BackgroundImg from "src/images/image_foodhall_hero@2x-min.png"
import IconMenuKadaiImg from "src/images/icon_menu_kadai@2x-min.png"
import IconMenuKadaiSelectedImg from "src/images/icon_menu_kadai_selected@2x-min.png"
import IconMenuKaiketsuImg from "src/images/icon_menu_kaiketsu@2x-min.png"
import IconMenuKaiketsuSelectedImg from "src/images/icon_menu_kaiketsu_selected@2x-min.png"
import IconMenuJissenImg from "src/images/icon_menu_jissen@2x-min.png"
import IconMenuJissenSelectedImg from "src/images/icon_menu_jissen_selected@2x-min.png"
import IconTagLocationImg from "src/images/icon_tag_location@2x-min.png"
import IconTagUserImg from "src/images/icon_tag_user@2x-min.png"
import IconTagKadaiImg from "src/images/icon_tag_kadai@2x-min.png"
import IconTagKaiketsuImg from "src/images/icon_tag_kaiketsu@2x-min.png"
import IconKaiketsuUserImg from "src/images/icon_kaiketsu_user@2x-min.png"
import IconKaiketsuStoreImg from "src/images/icon_kaiketsu_store@2x-min.png"
import TitleHeaderImg from "src/images/title_foodhall_header@2x-min.png"
import TitleKadaiImg from "src/images/title_foodhall_kadai@2x-min.png"
import ImageKadai1Img from "src/images/image_foodhall_kadai_1@2x-min.png"
import ImageKadai2Img from "src/images/image_foodhall_kadai_2@2x-min.png"
import TitleKadaiHopeImg from "src/images/title_kadai_hope@2x-min.png"
import ImageKadaiHopeImg from "src/images/image_kadai_hope@2x-min.png"
import ImageKadaiArrowImg from "src/images/image_kadai_arrow@2x-min.png"
import TitleKaiketsuImg from "src/images/title_foodhall_kaiketsu@2x-min.png"
import ImageKaiketsuMainImg from "src/images/image_foodhall_kaiketsu_main@2x-min.png"
import ImageKaiketsuUser1Img from "src/images/image_foodhall_kaiketsu_user_1@2x-min.png"
import ImageKaiketsuUser2Img from "src/images/image_foodhall_kaiketsu_user_2@2x-min.png"
import ImageKaiketsuUser3Img from "src/images/image_foodhall_kaiketsu_user_3@2x-min.png"
import ImageKaiketsuStore1Img from "src/images/image_foodhall_kaiketsu_store_1@2x-min.png"
import ImageKaiketsuStore2Img from "src/images/image_foodhall_kaiketsu_store_2@2x-min.png"
import ImageKaiketsuStore3Img from "src/images/image_foodhall_kaiketsu_store_3@2x-min.png"
import ImageJissen1Img from "src/images/image_jissen_1@2x-min.png"
import ImageJissen2Img from "src/images/image_jissen_2@2x-min.png"
import ImageJissen3Img from "src/images/image_jissen_3@2x-min.png"
import ImageFreeImg from "src/images/image_free@2x-min.png"
import ImageTime15Img from "src/images/image_time_15@2x-min.png"
import ImageMobileorderAppsImg from "src/images/image_mobileorder_apps@2x-min.png"
import ImageIllustMobileImg from "src/images/image_illust_mobile@2x-min.png"
import ImageIllustWifiImg from "src/images/image_illust_wifi@2x-min.png"
import ImageIllustOperatorImg from "src/images/image_illust_operator@2x-min.png"
import TitleTryImg from "src/images/title_foodhall_try@2x-min.png"
import curve from "src/images/curve.svg"

const IconTag = ({ icon, title }) => (
  <span className="tag has-background-primary has-text-white">
    { icon && <span className="icon"><i className={icon}></i></span> }
    <strong className="has-text-white">{ title }</strong>
  </span>
)

const LabelTag = ({ icon, label, title }) => (
  <div className="columns is-mobile">
    <div className="column py-2 is-4">
      <div className="is-flex" style={{alignItems: "center"}}>
        <span className="icon is-small mr-2"><img src={icon} alt="" /></span>
        <span><strong>{label}</strong></span>
      </div>
    </div>
    <div className="column py-2"><IconTag title={title} /></div>
  </div>
)

const Hero = () => (
  <div>
    <HeroTablet />
    <HeroTarget />
  </div>
)

const TargetList = ({ basis }) => {
  const Columns = ({ className, children }) => (
    basis ? <div className={className}>{children}</div> : children
  )
  const Column = ({ className, children }) => (
    basis ? <div className={className} style={{ flexBasis: basis }}>{children}</div> : children
  )

  return (
    <Columns className="columns is-mobile is-multiline">
      <Column className="column">
        <LabelTag icon={IconTagLocationImg} label="場所" title="フードホール" />
      </Column>
      <Column className="column">
        <LabelTag icon={IconTagUserImg} label="ユーザー" title="来場者（グループ）" />
      </Column>
      <Column className="column">
        <LabelTag icon={IconTagKadaiImg} label="課題" title="注文が大変" />
      </Column>
      <Column className="column">
        <LabelTag icon={IconTagKaiketsuImg} label="解決法" title="モバイルオーダー" />
      </Column>
    </Columns>
  )
}

const HeroTablet = () => (
  <section className="hero" style={{ background: `#fff url(${BackgroundImg}) no-repeat center / cover` }}>
    <div className="hero-body pt-5">
      <div className="container">
        <div><Link to="/casestudy"><span className={`tag has-background-white has-text-black ${Style.hoverColor}`}>&lt; 活用例一覧へ</span></Link></div>
        <div className="mt-3"><span className="tag is-normal has-background-info has-text-white">NEW!!</span></div>
        <figure className="image my-3"><img src={TitleHeaderImg} alt="" style={{ maxWidth: "512px" }}/></figure>
        <div className="box is-hidden-mobile mt-3 is-inline-block is-size-7" style={{ width: "340px", background: "#dbe8ea" }}>
          <TargetList />
        </div>
        <Spacer />
      </div>
    </div>
  </section>
)

const HeroTarget = () => (
  <div className="section is-hidden-tablet" style={{ background: "#dbe8ea", padding: "1rem" }}>
    <div className="container is-size-7">
      <TargetList basis="312px" />
    </div>
  </div>
)

const NaviTab = () => {
  const [active, setActive] = useState("#problem")
  const handleClick = (e) => {
    setActive(e.target.name)
  }

  const handleScroll = (e) => {
    checkFixedTop()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    checkFixedTop(true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  })

  const getActiveLink = () => {
    var link = ""
    for(const target of ["problem", "solution", "practice"]){
      if(Math.floor(document.getElementById(target).getBoundingClientRect().top) > 0){
        return link
      }
      link = target
    }
    return link
  }

  const checkFixedTop = (init) => {
    if(fixed !== (heightNavbar() >= heightStepNav())) {
        setFixed(!fixed)
    }
    let activeLink = "#" + getActiveLink()
    if(active !== activeLink){
      setActive(activeLink)
    }
  }

  const heightStepNav = () => {
    const nav = document.getElementById("stepnav")
    return nav ? nav.getBoundingClientRect().top : 0
  }

  const heightNavbar = () => {
    const navbar = document.getElementById("navbar")
    return navbar ? navbar.offsetHeight : 0
  }

  const [fixed, setFixed] = useState(false)
  const fixedStyle = { position: "fixed", top: "3.2rem", left: "0", right: "0", zIndex: "10", boxShadow: "0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)" }
  const normalBorder = { borderBottom: "solid 2px #000" }
  const activeBorder = { borderBottom: "solid 2px #09b4c6" }

  const TabItem = ({title, img, imgSelected, link}) => (
    <div className="column is-flex" style={{alignItems: "center", justifyContent: "center"}}>
      <div className={`pl-2 pr-3 ${Style.hoverNavitem}`} style={ active === link ? activeBorder : normalBorder }>

      <a href={link} name={link} onClick={handleClick}>
        <div className={`is-flex`} style={{alignItems: "center"}}>
          <span className="icon is-small mr-2"><img src={ active === link ? imgSelected : img } alt=""/></span>
          <span className={ active === link ? "is-size-7-mobile has-text-primary" : "is-size-7-mobile has-text-black" } style={{letterSpacing: ".2rem"}}><strong>{title}</strong></span>
        </div>
      </a>

      </div>
    </div>
  )
  return (
    <div id="stepnav" className="has-background-white" style={{position: "relative", height: "3rem"}}>
      <div className="section has-background-white py-4" style={fixed ? fixedStyle : { width: "100%" }}>
        <div className="container">
          <div className="columns is-mobile">
              <TabItem link="#problem" title="課題" img={IconMenuKadaiImg} imgSelected={IconMenuKadaiSelectedImg} />
              <TabItem link="#solution" title="解決" img={IconMenuKaiketsuImg} imgSelected={IconMenuKaiketsuSelectedImg} />
              <TabItem link="#practice" title="実践" img={IconMenuJissenImg} imgSelected={IconMenuJissenSelectedImg} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Text = ({ children, last }) => (
  <div className={last === true ? "mt-3" : "my-3"} style={{ lineHeight: "1.75"}}>{children}</div>
)

const BubbleBox = ({ children }) => (
  <div className="box has-background-info is-shadowless" style={{ position: "relative" }}>
    <div className="is-hidden-mobile" style={{ position: "absolute", top: "50%", left: "100%", marginTop: "-15px", border: "15px solid transparent", borderLeft: "15px solid #FFC72E" }} ></div>
    {children}
  </div>
)

const Problem = () => (
  <div id="problem" style={{ marginTop: "-6.25rem", paddingTop: "6.25rem" }}>
    <div className="section" style={{ background: "#dbe8ea" }}>
      <div className="container">
        <div className="columns">
          <div className="column is-narrow">

        <figure className="image is-hidden-mobile my-6"><img src={ImageKadai1Img} alt="" style={{ maxWidth: "320px" }}/></figure>
        <figure className="image is-hidden-mobile mt-6"><img src={ImageKadai2Img} alt="" style={{ maxWidth: "320px" }}/></figure>
  
          </div>
          <div className="column">
  
        <figure className="image mb-6"><img src={TitleKadaiImg} alt="" style={{ maxWidth: "478px", margin: "auto" }}/></figure>
        <Text><p>ピザにパスタ、ステーキ、クラフトビール、香り高い珈琲、ジェラート・・・色々なジャンルの中から、自分の食べたいものを好きに選ぶことができるのがフードホールの魅力です。買い物ついでに寄るフードコートとは異なり、おしゃれな空間での食事そのものを目当てに訪れる人も少なくありません。</p></Text>
        <figure className="image is-hidden-tablet"><img src={ImageKadai1Img} alt="" style={{ maxWidth: "348px", margin: "auto" }}/></figure>
        <Text><p>しかし、注文するには<strong>席を離れてお店に並ぶ</strong>必要があり、また<strong>どんなメニューがあるかは実際にお店の前に行かないとわからない</strong>場合も多く、<strong>混雑する</strong>ことがネックでもあります。</p></Text>
        <figure className="image is-hidden-tablet"><img src={ImageKadai2Img} alt="" style={{ maxWidth: "348px", margin: "auto" }}/></figure>
        <Text last={true}><p>せっかく気の置けない仲間との食事会をセッティングしたのに、<strong>みんなバラバラでお店に並んで、時間もかかって、なかなか揃って食事が始められない・・・</strong>なんて残念ですよね。</p></Text>

          </div>
        </div>
        <div className="columns" style={{ flexDirection: "row-reverse", alignItems: "flex-end" }}>
          <div className="column is-two-fifths">
              <div className="is-flex" style={{ width: "60%" }}>
                <figure className="image" style={{ maxWidth: "210px", marginLeft: "auto" }}><img src={TitleKadaiHopeImg} alt="" /></figure>
              </div>
            <figure className="image"><img src={ImageKadaiHopeImg} alt="" style={{ width: "75%", maxWidth: "260px", margin: "auto" }}/></figure>
          </div>
          <div className="column">
            <BubbleBox><p><strong>席にいながら各店のメニューをじっくり見たい！</strong></p></BubbleBox>
            <BubbleBox><p><strong>そのまま席から注文したい！</strong></p></BubbleBox>
            <BubbleBox><p><strong>できるだけ席を離れたくない！</strong></p></BubbleBox>
          </div>
          <div className="column is-narrow is-hidden-mobile">
            <div className="px-4" />
          </div>
        </div>
      </div>
    </div>
    <div className="has-background-primary" style={{ height: "70px", background: `url(${curve}) top / 100% no-repeat` }}>
      <figure className="image"><img src={ImageKadaiArrowImg} alt="" style={{ maxWidth: "104px", marginLeft: "auto", marginRight: "auto" }} /></figure>
    </div>
  </div>
)

const FeatureBoxItem = ({title, description, number, img, alt}) => (
  <div className="has-text-centered">
    <figure className="image my-4">
      <div style={{ width: "50%", maxWidth: "120px", margin: "auto", position: "relative"}}>
        <img src={img} alt={alt} />
        <div className="has-text-white" style={{position: "absolute", top: "0", left: "0", transform: "translateX(-2.2rem)", width: "2rem", height: "2rem", backgroundColor: "#ffc72e", borderRadius: "50%"}}>
          <div className="has-text-centered is-flex" style={{height: "100%", alignItems: "center", justifyContent: "center"}}><span><strong className="has-text-white">{number}</strong></span></div>
        </div>
      </div>
    </figure>
    <div className={`is-size-5 is-size-6-mobile has-text-weight-bold my-3`} style={{letterSpacing: ".15rem"}}>{title}</div>
    <div className="is-size-6 is-size-7-mobile" style={{lineHeight: "1.75"}}>{description}</div>
  </div>
)

const FeatureBoxHeader = ({title, icon}) => (
  <div className="has-background-link has-text-white has-text-centered">
    <div className="columns is-mobile is-centered is-vcentered is-gapless">
      <div className="column is-narrow"><span className="icon is-large mx-3 my-3"><img src={icon} alt="" /></span></div>
      <div className="column is-narrow"><strong className="has-text-white" style={{letterSpacing: ".2rem"}}>{title}</strong></div>
      <div className="column is-narrow"><p className="is-size-7 has-text-weight-bold">に嬉しい！</p></div>
    </div>
  </div>
)

const Solution = () => (
  <div id="solution" style={{ marginTop: "-6.25rem", paddingTop: "6.25rem" }}>
    <div className={`section has-background-primary pb-0 ${PatternStyle.pattern}`}>
      <div className="container">
        <figure className="image mb-5"><img src={TitleKaiketsuImg} alt="" style={{ maxWidth: "428px", marginLeft: "auto", marginRight: "auto" }}/></figure>
        <div className="subtitle has-text-centered has-text-white has-text-weight-bold">
          <div className="is-size-6" style={{ lineHeight: "2" }}><Wrap><span>スマホから商品の注文ができる</span><span>「モバイルオーダー」。</span><br />
          { /* natadeCOCO なら〜で簡単 */ }
          <span>この仕組みを使えば、フードホールが</span><span>もーっと特別な場所に！</span></Wrap></div>
        </div>

      </div>
    </div>
    <div className="section has-background-primary"> 
      <div className="container">

        <figure className="image"><img src={ImageKaiketsuMainImg} alt="" style={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px", marginLeft: "auto", marginRight: "auto" }}/></figure>

        <FeatureBoxHeader title="利用者" icon={IconKaiketsuUserImg} />

        <div className="box is-radiusless is-shadowless has-background-white px-1 pb-6 mb-0">
          <div className="columns is-mobile is-multiline is-gapless is-centered">
            <div className="column" style={{ flexBasis: "136px" }}>
              <FeatureBoxItem
                number="1"
                title={<span>メニューは<br />スマホで閲覧</span>}
                description={<Wrap><span>QRコードから</span><span>注文アプリを起動！</span><br /><span>その場でゆっくり</span><span>商品を選べます。</span></Wrap>}
                img={ImageKaiketsuUser1Img} alt=""
              />
            </div>
            <div className="column" style={{ flexBasis: "136px" }}>
              <FeatureBoxItem
                number="2"
                title={<span>そのまま<br />スマホで注文</span>}
                description={<Wrap><span>商品は注文アプリから</span><span>オーダー！</span><br /><span>長い列に並ばなくて</span><span>いいんです。</span></Wrap>}
                img={ImageKaiketsuUser2Img} alt=""
              />
            </div>
            <div className="column" style={{ flexBasis: "136px" }}>
              <FeatureBoxItem
                number="3"
                title={<span>離席は商品の<br />受け取り時だけ</span>}
                description={<span>準備できたらスマホへお知らせ！<br />お店に取りに行きましょう。</span>}
                img={ImageKaiketsuUser3Img} alt=""
              />
            </div>
          </div>
        </div>

        <FeatureBoxHeader title="店舗" icon={IconKaiketsuStoreImg} />

        <div className="box is-shadowless has-background-white px-1 pb-6" style={{ borderTopLeftRadius: "0", borderTopRightRadius: "0" }}>
          <div className="columns is-mobile is-multiline is-gapless is-centered">
            <div className="column" style={{ flexBasis: "136px" }}>
              <FeatureBoxItem
                number="1"
                title={<span>メニューの登録<br />・変更もらくらく</span>}
                description={<Wrap><span>専用サイトから</span><span>商品情報を入力！</span><br /><span>すぐに注文アプリに</span><span>反映されます。</span></Wrap>}
                img={ImageKaiketsuStore1Img} alt=""
              />
            </div>
            <div className="column" style={{ flexBasis: "136px" }}>
              <FeatureBoxItem
                number="2"
                title={<span>注文が入ると<br />アプリがお知らせ</span>}
                description={<Wrap><span>注文受付も</span><span>受注アプリにおまかせ！</span><br /><span>少人数でも無理なく</span><span>お店を回せます。</span></Wrap>}
                img={ImageKaiketsuStore2Img} alt=""
              />
            </div>
            <div className="column" style={{ flexBasis: "136px" }}>
              <FeatureBoxItem
                number="3"
                title={<span>呼び出しも<br />ボタン１つで</span>}
                description={<span>商品が準備できたら、受注アプリで<br />ステータスを切り替えるだけでOK！</span>}
                img={ImageKaiketsuStore3Img} alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const StepBox = ({ img, title, desc }) => (
  <div className="box is-shadowless px-3 py-3" style={{flex: "1"}}>
    <div className="columns is-mobile is-multiline is-centered is-vcentered" style={{maxWidth: "500px", margin: "auto"}}>
      <div className="column is-paddingless is-4-mobile is-full-tablet">
        <figure className="image"><img src={img} style={{ maxWidth: "140px", margin: "auto" }} alt="" /></figure>
      </div>
      <div className="column is-full-tablet pr-0">
        <div className="title is-4 mb-3 has-text-primary">{title}</div>
        <div>{desc}</div>
      </div>
      <div className="column is-narrow is-hidden-mobile px-1 py-1"></div>
    </div>
  </div>
)

const BubbleRoundBox = ({ children, color }) => {
  const bgcolor = color ? color : "#ffffff"

  return (
    <div className="box is-shadowless pl-4 pr-4 py-2" style={{ backgroundColor: bgcolor, borderRadius: "25px", position: "relative" }}>
      <div style={{ position: "absolute", top: "-30px", left: "50%", marginLeft: "-15px", border: "15px solid transparent", borderBottom: `15px solid ${bgcolor}` }} ></div>
      {children}
    </div>
  )
}

const DotLine = () => (
  <div style={{flexGrow: "1", height: "20px", opacity: "0.5", backgroundImage: "radial-gradient(circle farthest-side, #fff, #fff 30%, transparent 40%, transparent)", backgroundSize: "16px 16px", backgroundRepeat: "repeat-x"}}></div>
)

const Practice = () => (
  <div id="practice" style={{ marginTop: "-6.25rem", paddingTop: "6.25rem" }}>
    <div className={`section has-background-primary pb-0 ${PatternStyle.pattern}`}>
      <div className="container">
        <figure className="image"><img src={TitleTryImg} alt="" style={{ maxWidth: "428px", marginLeft: "auto", marginRight: "auto" }}/></figure>

        <div className="mb-5 is-flex" style={{justifyContent: "center", marginTop: "2rem"}}>
          <BubbleRoundBox>
            <div className="is-flex" style={{alignItems: "center"}}>
              <figure className="image mr-2"><img src={ImageFreeImg} style={{width: "2.5rem"}} alt="" /></figure>
              <span className="has-text-primary has-text-weight-bold is-size-5 is-size-6-mobile" style={{letterSpacing: "0.1rem"}}>今すぐお試しいただけます</span>
            </div>
          </BubbleRoundBox>
        </div>

        <div className="columns">
          <div className="column is-half">
            <figure className="image mt-3"><img src={ImageMobileorderAppsImg} alt="" style={{ maxWidth: "479px", margin: "auto" }}/></figure>
          </div>
          <div className="column">
            <Spacer isHalf className="is-hidden-mobile" />
            <div className="columns is-mobile" style={{maxWidth: "684px", margin: "auto"}}>
              <div className="column is-narrow" style={{width: "30%", paddingRight: "0.5rem", paddingLeft: "0.5rem"}}>
                <HeaderBox title="所要時間">
                  <div className="is-flex" style={{padding: "0.5em", height: "100%", justifyContent: "center"}}>
                    <figure className="image my-3" style={{width: "90%"}}><img src={ImageTime15Img} alt="" /></figure>
                  </div>
                </HeaderBox>
              </div>
              <div className="column is-narrow" style={{width: "40%", paddingRight: "0.5rem", paddingLeft: "0.5rem"}}>
                <HeaderBox title="用意するもの">
                  <div className="is-flex" style={{justifyContent: "space-around"}}>
                    <div className="is-flex" style={{padding: "0.5rem", flexDirection: "column"}}>
                      <div style={{flex: "1"}}><figure className="image"><img src={ImageIllustMobileImg} alt="" /></figure></div>
                      <div className="has-text-centered"><span className="has-text-link has-text-weight-bold is-size-7-touch">スマホ</span></div>
                    </div>
                    <div className="is-flex" style={{padding: "0.5rem", flexDirection: "column"}}>
                      <div className="is-flex" style={{flex: "1", alignItems: "center", justifyContent: "center"}}><figure className="image"><img src={ImageIllustWifiImg} alt="" /></figure></div>
                      <div className="has-text-centered"><span className="has-text-link has-text-weight-bold is-size-7-touch">Wi-Fi</span></div>
                    </div>
                  </div>
                </HeaderBox>
              </div>
              <div className="column is-narrow" style={{width: "30%", paddingRight: "0.5rem", paddingLeft: "0.5rem"}}>
                <HeaderBox title="お申し込み">
                  <div className="is-flex" style={{padding: "0.5rem", flexDirection: "column"}}>
                    <div style={{flex: "1"}}><figure className="image"><img src={ImageIllustOperatorImg} alt="" /></figure></div>
                    <div className="has-text-centered"><span className="has-text-link has-text-weight-bold is-size-7-mobile">不要</span></div>
                  </div>
                </HeaderBox>
              </div>
            </div>
            <div className="content has-text-white has-text-weight-bold">
              <ul style={{lineHeight: "2rem"}}>
                <li><Wrap><span>基本機能を備えた</span><span>２つのウェブアプリが</span><span>無料で利用可能</span></Wrap></li>
                <li><p>スマホとWi-FiがあればOK</p></li>
                <li><p>導入作業はWebで完結、15分で完了</p></li>
              </ul>
              <div className="is-size-7 ml-3"><Wrap><span>＊アプリの機能追加・カスタマイズも可能です。</span><span>お気軽にご相談ください。</span></Wrap></div>
            </div>
          </div>
        </div>
        <div className="is-flex is-size-5 is-size-6-mobile has-text-centered-mobile has-text-white has-text-weight-bold" style={{alignItems: "center"}}>
          <DotLine />
          <div style={{flexGrow: "0"}}><div className="mx-3">導入はたった<span className="is-size-2 mx-3" style={{verticalAlign: "middle"}}>3</span>ステップで完了</div></div>
          <DotLine />
        </div>
      </div>
    </div>
    <div className="section has-background-primary">
      <div className="container">
        <div className="columns">
          <div className="column is-flex has-text-centered-tablet">
            <StepBox title="STEP1" img={ImageJissen1Img} desc={<Wrap><span>配信ユニットに</span><br /><span>アプリ(注文用<span className="is-size-7-mobile" style={{verticalAlign: "middle"}}>・</span>受注用)を</span><span>インストール！</span></Wrap>} />
          </div>
          <div className="column is-narrow is-flex" style={{alignItems: "center", justifyContent: "center"}}>
            <div className="is-size-4 has-text-white has-text-centered"><span className="is-hidden-mobile">▶︎</span><span className="is-hidden-tablet">▼</span></div>
          </div>
          <div className="column is-flex has-text-centered-tablet">
            <StepBox title="STEP2" img={ImageJissen2Img} desc={<Wrap><span>メニュー用の</span><br /><span>商品情報を登録！</span></Wrap>} />
          </div>
          <div className="column is-narrow is-flex" style={{alignItems: "center", justifyContent: "center"}}>
            <div className="is-size-4 has-text-white"><span className="is-hidden-mobile">▶︎</span><span className="is-hidden-tablet">▼</span></div>
          </div>
          <div className="column is-flex has-text-centered-tablet">
            <StepBox title="STEP3" img={ImageJissen3Img} desc={<Wrap><span>さっそく</span><br /><span>注文してみましょう！</span></Wrap>} />
          </div>
        </div>
        <div className="mt-5 has-text-centered">
          <div className="is-flex" style={{justifyContent: "center"}}>
            <a href="/guide/#/case-study/mobile-order" target="_self" rel="noopener noreferrer">
              <div className={`has-background-info ${Style.hoverColor}`} style={{borderRadius: "6px", padding: "1rem"}}><div className="px-6 has-text-black"><strong style={{letterSpacing: "0.1rem"}}>さっそく試す</strong></div></div>
            </a>
          </div>
        </div>
        <div className="mt-5 is-flex" style={{justifyContent: "center"}}>
          <BubbleRoundBox color="#17799D">
            <div className="is-flex" style={{alignItems: "center", justifyContent: "center"}}>
              <div className="mr-3" style={{width: "5rem"}}><span className="figure" style={{minWidth: "64px"}}><img src={ImageFreeImg} alt="free"/></span></div>
              <div className="columns is-gapless has-text-white has-text-weight-bold is-size-6 is-size-7-mobile" style={{alignItems: "baseline", letterSpacing: "0.08rem"}}>
                <div className="column is-narrow">クラウド版プレビュープランなら<span className="pl-1 is-hidden-mobile"></span></div>
                <div className="column is-narrow"><span className="is-size-4 pr-1">24</span>時間<span className="is-size-4 px-1">無料</span>で使える！</div>
              </div>
            </div>
          </BubbleRoundBox>
        </div>
      </div>
    </div>
  </div>
)

const IndexPage = () => (
  <Layout>
    <Seo ogpImage="https://natade-coco.gitlab.io/assets/scene_foodhall_hero.png" />
    <Header />
    <main>
      <Hero />
      <NaviTab />
      <Problem />
      <Solution />
      <Practice />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
