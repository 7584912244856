import React from "react"

const HeaderBox = ({ title, children }) => (
  <div className="is-flex" style={{ flexDirection: "column", height: "100%" }}>
    <div className="has-background-link has-text-white has-text-centered has-text-weight-bold" style={{borderTopLeftRadius: "6px", borderTopRightRadius: "6px", padding: "0.25rem"}}>
      <div className="is-size-7-touch">{title}</div>
    </div>
    <div className="has-background-white" style={{ flex: "1", borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px"}}>
      {children}
    </div>
  </div>
)

export default HeaderBox
